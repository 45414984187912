"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CsvService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class CsvService {
    /**
     * @returns CsvResponseDto
     * @throws ApiError
     */
    static createCsvExport({ receiptType, receiptId, exportingUsername, companyId, operatingUnitId, verbosePositions, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/csv/export',
            query: {
                'receiptType': receiptType,
                'receiptId': receiptId,
                'exportingUsername': exportingUsername,
                'verbosePositions': verbosePositions,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * `dateFrom`: "YYYY-MM-DD", `dateTo`: "YYYY-MM-DD"
     * @returns CsvResponseDto
     * @throws ApiError
     */
    static createCsvBatchExport({ receiptType, dateFrom, dateTo, includeFormerlyExported, companyId, operatingUnitId, exportingUsername, receiptStatus, verbosePositions, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/csv/export/batch',
            query: {
                'receiptType': receiptType,
                'dateFrom': dateFrom,
                'dateTo': dateTo,
                'receiptStatus': receiptStatus,
                'includeFormerlyExported': includeFormerlyExported,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
                'exportingUsername': exportingUsername,
                'verbosePositions': verbosePositions,
            },
        });
    }
}
exports.CsvService = CsvService;
