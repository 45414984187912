import { getInvitations } from "@farmact/customer-portal-api-client";
import { Invitation } from "@farmact/model/src/model/Invitation";
import { Role } from "@farmact/model/src/model/Role";
import { useEffect, useState } from "react";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import { computed } from "@/util/functions";
import { useRole } from "./useRole";

type UseAppInvitationsReturnValue = {
    customerInvitations: Invitation[];
    employeeInvitations: Invitation[];
};

export function useAppInvitations(authUserId: string | undefined): UseAppInvitationsReturnValue {
    const { role } = useRole();
    const isCustomerApi = role === Role.CUSTOMER;

    const [invitations, setInvitations] = useState<Invitation[]>([]);

    const query = computed(() => {
        if (!role) {
            return undefined;
        }
        if (isCustomerApi) {
            return undefined;
        }
        if (authUserId) {
            return Firebase.instance().getAllInvitationsForInvitedAppUserId(authUserId);
        }
        return undefined;
    });
    const [firebaseInvitations] = useCollectionData(query, [authUserId]);

    useEffect(() => {
        if (!authUserId) {
            return;
        }
        const fetchInvitations = async () => {
            const invitationsFromDB = await getInvitations();
            setInvitations(
                invitationsFromDB.map(invitation => new Invitation({ ...invitation, role: invitation.role as Role }))
            );
        };
        if (isCustomerApi) {
            fetchInvitations();
        }
    }, [authUserId, isCustomerApi]);

    const employeeInvitations = isCustomerApi
        ? invitations.filter(invitation => invitation.role !== Role.CUSTOMER && invitation.role !== Role.NOTHING)
        : firebaseInvitations.filter(
              invitation => invitation.role !== Role.CUSTOMER && invitation.role !== Role.NOTHING
          );

    const customerInvitations = isCustomerApi
        ? invitations.filter(invitation => invitation.role === Role.CUSTOMER)
        : firebaseInvitations.filter(invitation => invitation.role === Role.CUSTOMER);

    return {
        customerInvitations,
        employeeInvitations,
    };
}
