"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransferService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class TransferService {
    /**
     * Get all transfers
     * @returns TransfersResponseDto
     * @throws ApiError
     */
    static getTransfers({ companyId, operatingUnitId, receiptTypes, status, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/transfers',
            query: {
                'receiptTypes': receiptTypes,
                'status': status,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * Export a receipt to DATEV
     * Export a receipt by id to DATEV providing the generated PDF.
     * @returns TransferResponseDto
     * @throws ApiError
     */
    static createTransfer({ receiptType, receiptId, exportingUsername, companyId, operatingUnitId, verbosePositions, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/transfers',
            query: {
                'receiptType': receiptType,
                'receiptId': receiptId,
                'exportingUsername': exportingUsername,
                'verbosePositions': verbosePositions,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * Get a transfer by id
     * @returns TransferResponseDto
     * @throws ApiError
     */
    static getTransfer({ id, companyId, operatingUnitId, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/transfers/{id}',
            path: {
                'id': id,
            },
            query: {
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
}
exports.TransferService = TransferService;
