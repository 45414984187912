import { OpenAPI as CustomerApi } from "@farmact/customer-portal-api-client";
import { environment } from "@/config/config";

const localCustomerPortalApiPath = "/localApi/customerPortalApi";

export function setupCustomerPortalApi() {
    if (environment == "local") {
        CustomerApi.BASE = localCustomerPortalApiPath;
    }

    if (environment === "dev" || environment === "alpha") {
        CustomerApi.BASE = "https://dev.api.customer-portal.farmact.de";
    }

    if (environment === "beta") {
        CustomerApi.BASE = "https://beta.api.customer-portal.farmact.de";
    }

    if (environment === "prod") {
        CustomerApi.BASE = "https://api.customer-portal.farmact.de";
    }
}
