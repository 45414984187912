import { Vertex } from "@farmact/model/src/model/Vertex";
import { computeArea, computeLength } from "spherical-geometry-js";
export function computeShapeToAreaHa(shape: Vertex[]): number {
    const area = computeArea(shape);
    return area / 10_000;
}

export function computeShapeToLengthMeters(shape: Vertex[]): number {
    if (shape.length < 2) {
        return 0;
    }
    return computeLength(shape);
}
