import { OpenAPI as CustomerApi } from "@farmact/customer-portal-api-client";
import { OpenAPI as DatevApi } from "@farmact/datev-integration-client";
import { OpenAPI as FinApi } from "@farmact/fin-service-client";
import { OpenAPI } from "@farmact/invoice-api-client";
import { OpenAPI as MailApi } from "@farmact/mail-service-client";
import { OpenAPI as NotificationApi } from "@farmact/notification-app-client";
import { OpenAPI as OcrApi } from "@farmact/ocr-service-client";
import { OpenAPI as TrackerApi } from "@farmact/tracker-api-client";
import { getAuth, onIdTokenChanged } from "firebase/auth";
import { useEffect } from "react";

export function useConfigureApiClients() {
    useEffect(() => {
        return onIdTokenChanged(getAuth(), async nextUser => {
            if (nextUser) {
                const token = await nextUser.getIdToken();
                OpenAPI.TOKEN = token;
                MailApi.TOKEN = token;
                FinApi.TOKEN = token;
                TrackerApi.TOKEN = token;
                NotificationApi.TOKEN = token;
                DatevApi.TOKEN = token;
                OcrApi.TOKEN = token;
                CustomerApi.TOKEN = token;
            } else {
                OpenAPI.TOKEN = undefined;
                MailApi.TOKEN = undefined;
                FinApi.TOKEN = undefined;
                TrackerApi.TOKEN = undefined;
                NotificationApi.TOKEN = undefined;
                DatevApi.TOKEN = undefined;
                OcrApi.TOKEN = undefined;
                CustomerApi.TOKEN = undefined;
            }
        });
    }, []);
}
