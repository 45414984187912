import * as Routes from "@farmact/model/src/constants/farmActAppRoutes";
import { Variant } from "@farmact/model/src/model/CompanyMeta";
import { Role } from "@farmact/model/src/model/Role";
import {
    faAddressBook,
    faCaretRight,
    faClipboardList,
    faFileInvoice,
    faGear,
    faMagnifyingGlassChart,
    faMapMarkedAlt,
    faPowerOff,
    faSitemap,
    faTools,
    faTractor,
    faUserCircle,
    faUserClock,
} from "@fortawesome/free-solid-svg-icons";
import { faTruckContainer } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "clsx";
import { SyntheticEvent, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "@/components/authentication/Session/UserContext";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import { useAppInvitations } from "@/util/customHooks/useAppInvitations";
import { useCurrentActivity } from "@/util/customHooks/useCurrentActivity";
import { useRole } from "@/util/customHooks/useRole";
import { AskToStopWorkdayModal } from "./AskToStopWorkdayModal/AskToStopWorkdayModal";
import { LogoutAlert } from "./LogoutAlert/LogoutAlert";
import { SideMenuTile } from "./SideMenuTile/SideMenuTile";
import { OrganizationMenu } from "./SideMenuTile/subMenus/OrganizationMenu/OrganizationMenu";
import { ReceiptsMenu } from "./SideMenuTile/subMenus/ReceiptsMenu/ReceiptsMenu";
import "./sideNav.scss";

type SideNavProps = {
    isOpen?: boolean;
    isInSplitPane?: boolean;
};

export function SideNav(props: SideNavProps) {
    const { companyMeta, operatingUnits } = useAppCompanyContext();
    const { only } = useRole();
    const [showLogoutAlert, setShowLogoutAlert] = useState<boolean>(false);
    const { authUser } = useContext(UserContext);

    const { currentActivity } = useCurrentActivity();
    const [showAskToStopWorkdayModal, setShowAskToStopWorkdayModal] = useState(false);
    const { customerInvitations, employeeInvitations } = useAppInvitations(authUser?.uid);

    const [dueOrders] = useCollectionData(
        Firebase.instance().getCompanyId() && authUser?.uid ? Firebase.instance().getDueOrders() : undefined,
        [Firebase.instance().getCompanyId(), authUser?.uid]
    );

    const isAdminOrOwner = only(Role.OWNER, Role.ADMIN);
    const isAtLeastPlanner = only(Role.OWNER, Role.ADMIN, Role.PLANNER);
    const isAtLeastEmployee = only(Role.OWNER, Role.ADMIN, Role.PLANNER, Role.EMPLOYEE);
    const isAtLeastTemporaryWorker = only(Role.OWNER, Role.ADMIN, Role.PLANNER, Role.EMPLOYEE, Role.TEMPORARY_WORKER);
    const isAtLeastSubContractor = only(
        Role.OWNER,
        Role.ADMIN,
        Role.PLANNER,
        Role.EMPLOYEE,
        Role.TEMPORARY_WORKER,
        Role.SUBCONTRACTOR
    );
    const isCustomer = only(Role.CUSTOMER, Role.NOTHING);

    const showModal = (event: SyntheticEvent) => {
        event.stopPropagation();
        if (currentActivity) {
            setShowAskToStopWorkdayModal(true);
        } else {
            setShowLogoutAlert(true);
        }
    };

    const handleLogout = async () => {
        await Firebase.instance().doSignOut();
        location.reload();
    };

    const operatingUnitsWithBrokenConnectedBanks = operatingUnits.filter(
        operatingUnit => operatingUnit.connectedBanksNeedAttention
    ).length;

    return (
        <>
            <div
                className={cn([
                    "side-nav",
                    props.isInSplitPane && "side-nav--is-side-menu",
                    props.isOpen && "side-nav--is-open",
                ])}>
                <div className="side-nav__wrapper">
                    <Link
                        className={cn(
                            "side-nav__home-icon",
                            (!props.isInSplitPane || (props.isInSplitPane && props.isOpen)) &&
                                "side-nav__home-icon--visible"
                        )}
                        style={{ backgroundImage: "url('assets/farmact_banner.png')" }}
                        to={Routes.HOME}
                        onClick={e => {
                            if (props.isInSplitPane) {
                                e.stopPropagation();
                            }
                        }}
                    />

                    {isCustomer && (
                        <>
                            <div className="side-nav__heading">
                                <div className="side-nav__line" />
                                Kunden-Funktionen
                            </div>
                            <SideMenuTile
                                clickTarget={Routes.CONTRACTORS}
                                name="Dienstleister"
                                icon={faAddressBook}
                                badgeNumber={customerInvitations.length}
                                isInSplitPane={props.isInSplitPane}
                                menuIsOpen={props.isOpen}
                            />
                        </>
                    )}

                    {isAtLeastEmployee && (
                        <>
                            <div className="side-nav__heading">
                                <div className="side-nav__line" />
                                Administration
                            </div>
                            {isAtLeastPlanner && (
                                <SideMenuTile
                                    clickTarget={Routes.ORDERS}
                                    name="Aufträge"
                                    icon={faClipboardList}
                                    isInSplitPane={props.isInSplitPane}
                                    menuIsOpen={props.isOpen}
                                />
                            )}
                            {isAdminOrOwner && (
                                <>
                                    <SideMenuTile
                                        clickTarget={Routes.RECEIPTS}
                                        name="Belege"
                                        icon={faFileInvoice}
                                        isInSplitPane={props.isInSplitPane}
                                        menuIsOpen={props.isOpen}
                                        pathMatch={Routes.RECEIPTS}>
                                        <ReceiptsMenu isInSplitPane={!!props.isInSplitPane} />
                                    </SideMenuTile>
                                    <SideMenuTile
                                        clickTarget={Routes.EMPLOYEES_MAP}
                                        name="Mitarbeiterkarte"
                                        icon={faMapMarkedAlt}
                                        isInSplitPane={props.isInSplitPane}
                                        menuIsOpen={props.isOpen}
                                    />
                                </>
                            )}
                            <SideMenuTile
                                clickTarget={Routes.ORGANIZATION}
                                badgeNumber={operatingUnitsWithBrokenConnectedBanks}
                                name="Stammdaten"
                                icon={faSitemap}
                                isInSplitPane={props.isInSplitPane}
                                menuIsOpen={props.isOpen}>
                                <OrganizationMenu isInSplitPane={!!props.isInSplitPane} />
                            </SideMenuTile>

                            {isAdminOrOwner && (
                                <SideMenuTile
                                    clickTarget={Routes.REPORTS}
                                    name="Berichte & Analysen"
                                    icon={faMagnifyingGlassChart}
                                    isInSplitPane={props.isInSplitPane}
                                    menuIsOpen={props.isOpen}
                                />
                            )}
                        </>
                    )}
                    {isAtLeastSubContractor && (
                        <>
                            <div className="side-nav__heading">
                                <div className="side-nav__line" />
                                Fahrerbereich
                            </div>
                            <SideMenuTile
                                clickTarget={Routes.TASKS}
                                name="Meine Aufträge"
                                icon={companyMeta?.variant === Variant.LOGISTICS ? faTruckContainer : faTractor}
                                badgeNumber={dueOrders.length}
                                isInSplitPane={props.isInSplitPane}
                                menuIsOpen={props.isOpen}
                            />
                            {isAtLeastTemporaryWorker && (
                                <>
                                    <SideMenuTile
                                        clickTarget={Routes.INTERNAL_WORK}
                                        name="Interne Arbeiten"
                                        icon={faTools}
                                        isInSplitPane={props.isInSplitPane}
                                        menuIsOpen={props.isOpen}
                                    />
                                    <SideMenuTile
                                        clickTarget={Routes.TIME_TRACKING}
                                        name="Meine Arbeitszeiten"
                                        icon={faUserClock}
                                        isInSplitPane={props.isInSplitPane}
                                        menuIsOpen={props.isOpen}
                                    />
                                </>
                            )}
                        </>
                    )}
                    <div className="side-nav__heading">
                        <div className="side-nav__line" />
                        Konfiguration
                    </div>
                    {isAdminOrOwner && (
                        <SideMenuTile
                            clickTarget={Routes.SETTINGS}
                            name="Einstellungen"
                            icon={faGear}
                            isInSplitPane={props.isInSplitPane}
                            menuIsOpen={props.isOpen}
                        />
                    )}
                    <SideMenuTile
                        clickTarget={Routes.ACCOUNT}
                        name="Account"
                        icon={faUserCircle}
                        badgeNumber={employeeInvitations.length}
                        isInSplitPane={props.isInSplitPane}
                        menuIsOpen={props.isOpen}
                    />
                    <div
                        className={cn(
                            "side-nav__power",
                            (!props.isInSplitPane || (props.isOpen && props.isInSplitPane)) && "side-nav__power--open"
                        )}
                        onClick={showModal}>
                        <FontAwesomeIcon icon={faPowerOff} />
                        Abmelden
                    </div>
                </div>
                {props.isInSplitPane && (
                    <div className={cn(["side-nav__toggle", props.isOpen && "side-nav__toggle--open"])}>
                        <FontAwesomeIcon icon={faCaretRight} />
                    </div>
                )}
            </div>
            <LogoutAlert show={showLogoutAlert} onCancel={() => setShowLogoutAlert(false)} onConfirm={handleLogout} />
            <AskToStopWorkdayModal
                isOpen={showAskToStopWorkdayModal}
                onDismiss={() => {
                    setShowAskToStopWorkdayModal(false);
                }}
                handleLogout={handleLogout}
            />
        </>
    );
}
