import { lazy } from "react";
import { withOnlyImplicitOwner } from "@/components/authentication/Session/withOnlyImplicitOwner";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const PriceAgreementDetailsPageContent = lazy(() => import("./PriceAgreementDetailsPageContent"));

function PriceAgreementDetailsPageInternal() {
    return (
        <DefaultPage>
            <PriceAgreementDetailsPageContent />
        </DefaultPage>
    );
}

export const PriceAgreementDetailsPage = withOnlyImplicitOwner()(PriceAgreementDetailsPageInternal);
