"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.F_CODE = exports.F_ERROR_PREFIX = void 0;
exports.F_ERROR_PREFIX = `FDI`;
var F_CODE;
(function (F_CODE) {
    // ==============
    // GENERAL ERRORS
    // ==============
    // 4xx
    F_CODE["FDI_400"] = "Bad Request";
    F_CODE["FDI_401"] = "Unauthorized";
    F_CODE["FDI_402"] = "Payment Required";
    F_CODE["FDI_403"] = "Forbidden";
    F_CODE["FDI_404"] = "Not Found";
    F_CODE["FDI_405"] = "Method Not Allowed";
    F_CODE["FDI_406"] = "Not Acceptable";
    F_CODE["FDI_407"] = "Proxy Authentication Required";
    F_CODE["FDI_408"] = "Request Timeout";
    F_CODE["FDI_409"] = "Conflict";
    F_CODE["FDI_410"] = "Gone";
    F_CODE["FDI_411"] = "Length Required";
    F_CODE["FDI_412"] = "Precondition Failed";
    F_CODE["FDI_413"] = "Payload Too Large";
    F_CODE["FDI_414"] = "URI Too Long";
    F_CODE["FDI_415"] = "Unsupported Media Type";
    F_CODE["FDI_416"] = "Range Not Satisfiable";
    F_CODE["FDI_417"] = "Expectation Failed";
    F_CODE["FDI_418"] = "I`m a teapot (RFC 2324, RFC 7168)";
    F_CODE["FDI_421"] = "Misdirected Request";
    F_CODE["FDI_422"] = "Unprocessable Entity";
    F_CODE["FDI_423"] = "Locked (WebDAV; RFC 4918)";
    F_CODE["FDI_424"] = "Failed Dependency (WebDAV; RFC 4918)";
    F_CODE["FDI_425"] = "Too Early (RFC 8470)";
    F_CODE["FDI_426"] = "Upgrade Required";
    F_CODE["FDI_428"] = "Precondition Required (RFC 6585)";
    F_CODE["FDI_429"] = "Too Many Requests (RFC 6585)";
    F_CODE["FDI_431"] = "Request Header Fields Too Large (RFC 6585)";
    F_CODE["FDI_451"] = "Unavailable For Legal Reasons (RFC 7725)";
    // 5xx
    F_CODE["FDI_500"] = "Internal Server Error";
    F_CODE["FDI_501"] = "Not Implemented";
    F_CODE["FDI_502"] = "Bad Gateway";
    F_CODE["FDI_503"] = "Service Unavailable";
    F_CODE["FDI_504"] = "Gateway Timeout";
    F_CODE["FDI_505"] = "HTTP Version not supported";
    F_CODE["FDI_506"] = "Variant Also Negotiates";
    F_CODE["FDI_507"] = "Insufficient Storage";
    F_CODE["FDI_508"] = "Loop Detected";
    F_CODE["FDI_509"] = "Bandwidth Limit Exceeded";
    F_CODE["FDI_510"] = "Not Extended";
    F_CODE["FDI_511"] = "Network Authentication Required";
    // =============
    // CUSTOM ERRORS
    // =============
    /*
     * Custom errors follow this convention: FDI_<HTTP_STATUS_CODE>_<AREA>_<SPECIFIC_ERROR_CODE>
     *
     * The <AREA> helps organizing the error codes into topics where the error originated
     *
     * AREAS:
     * 01: service related
     * 02: DATEV auth related
     * 03: DATEV document API related
     * 04: DATEV extf API related
     * 05: DATEV clients api related
     * 06: Firebase
     * 10: CSV endpoint/service related
     * 11: Transfer endpoint/service related
     * 90: Database related
     *
     * The <SPECIFIC_ERROR_CODE> is a numeric code with 4 digits ranging from 0001 - 9999
     * giving detail about the specific error in the given <AREA>
     */
    // ===========================
    // AREA: 01 - INTERNAL SERVICE
    // ===========================
    /* 400 */
    F_CODE["FDI_400_01"] = "General internal service problem (bad request)";
    F_CODE["FDI_400_01_0001"] = "Validation problem";
    /* 401 */
    F_CODE["FDI_401_01"] = "General internal service problem (unauthorized)";
    F_CODE["FDI_401_01_0001"] = "No token provided";
    F_CODE["FDI_401_01_0002"] = "Invalid token";
    /* 403 */
    F_CODE["FDI_403_01"] = "General internal service problem (forbidden)";
    F_CODE["FDI_403_01_0001"] = "Company ID mismatch";
    F_CODE["FDI_403_01_0002"] = "Query param 'companyId' is missing";
    F_CODE["FDI_403_01_0003"] = "No role";
    F_CODE["FDI_403_01_0004"] = "Insufficient role";
    /* 404 */
    F_CODE["FDI_404_01"] = "General internal service problem (not found)";
    F_CODE["FDI_404_01_0001"] = "Login request not found or is expired";
    //  =====================
    //  AREA: 02 - DATEV AUTH
    //  =====================
    /* 401 */
    F_CODE["FDI_401_02"] = "General DATEV auth problem (unauthorized)";
    F_CODE["FDI_401_02_0001"] = "OAuth flow error";
    F_CODE["FDI_401_02_0002"] = "Login needed";
    F_CODE["FDI_401_02_0003"] = "Cannot refresh token";
    F_CODE["FDI_401_02_0004"] = "Access denied";
    /* 403 */
    F_CODE["FDI_403_02"] = "General DATEV auth problem (forbidden)";
    F_CODE["FDI_403_02_0001"] = "DATEV user has not enough rights to access the requested client";
    F_CODE["FDI_403_02_0002"] = "Required service 'Buchungsdatenservice' is not enabled or user does not have enough rights to access it";
    F_CODE["FDI_403_02_0003"] = "This DATEV instance is already connected to another Farmact account";
    /* 404 */
    F_CODE["FDI_404_02"] = "General DATEV auth problem (not found)";
    F_CODE["FDI_404_02_0001"] = "Cannot find datev auth request";
    //  ==============================
    //  AREA: 03 - DATEV DOCUMENTS API
    //  ==============================
    /* 400 */
    F_CODE["FDI_400_03"] = "General DATEV documents API problem (bad request)";
    F_CODE["FDI_400_03_0001"] = "The third-party solution has transmitted an incorrect request";
    F_CODE["FDI_400_03_0002"] = "User has no access to the requested client";
    F_CODE["FDI_400_03_0003"] = "The required scope is missing";
    F_CODE["FDI_400_03_0004"] = "The third-party solution does not have permission to use this request";
    /* 401 */
    F_CODE["FDI_401_03"] = "General DATEV documents API problem (unauthorized)";
    /* 403 */
    F_CODE["FDI_403_03"] = "General DATEV documents API problem (forbidden)";
    /* 409 */
    F_CODE["FDI_409_03"] = "General DATEV documents API problem (conflict)";
    /* 500 */
    F_CODE["FDI_500_03"] = "General DATEV documents API problem (internal server error)";
    /* 503 */
    F_CODE["FDI_503_03"] = "General DATEV documents API problem (service unavailable)";
    // ===============================
    // AREA: 04 - DATEV EXTF FILES API
    // ===============================
    /* 400 */
    F_CODE["FDI_400_04"] = "General DATEV extf files API problem (bad request)";
    F_CODE["FDI_400_04_0001"] = "Invalid message body";
    F_CODE["FDI_400_04_0002"] = "Invalid header fields";
    F_CODE["FDI_400_04_0003"] = "Invalid request uri";
    /* placeholder */
    F_CODE["FDI_400_04_1000"] = "CSV validation failed (TODO: check)";
    /* 401 */
    F_CODE["FDI_401_04"] = "General DATEV extf files api problem (unauthorized)";
    /* 403 */
    F_CODE["FDI_403_04"] = "General DATEV extf files api problem (forbidden)";
    F_CODE["FDI_403_04_0001"] = "Missing scopes datev:accounting:extf-files-import";
    /* 404 */
    F_CODE["FDI_404_04"] = "General DATEV extf files api problem (not found)";
    /* 406 */
    F_CODE["FDI_406_04"] = "General DATEV extf files api problem (not acceptable)";
    /* 413 */
    F_CODE["FDI_413_04"] = "General DATEV extf files api problem (payload too large)";
    /* 415 */
    F_CODE["FDI_415_04"] = "General DATEV extf files api problem (unsupported media type)";
    /* 500 */
    F_CODE["FDI_500_04"] = "General DATEV extf files api problem (internal server error)";
    F_CODE["FDI_500_04_0001"] = "retry-after or location header is missing";
    /* 503 */
    F_CODE["FDI_503_04"] = "General DATEV extf files api problem (service unavailable)";
    // ============================
    // AREA: 05 - DATEV clients API
    // ============================
    /* 401 */
    F_CODE["FDI_401_05"] = "General DATEV clients api problem (unauthorized)";
    // ===================
    // AREA: 06 - FIREBASE
    // ===================
    /* 400 */
    F_CODE["FDI_400_06"] = "General firebase problem (bad request)";
    /* 404 */
    F_CODE["FDI_404_06"] = "General firebase problem (not found)";
    F_CODE["FDI_404_06_0001"] = "Cannot find requested receipt in firebase database";
    F_CODE["FDI_404_06_0002"] = "Cannot find requested company settings in firebase database";
    F_CODE["FDI_404_06_0003"] = "Cannot find requested datev property in firebase database. See details for more information";
    F_CODE["FDI_404_06_0004"] = "Cannot find requested datev property \"defaultRevenueAccount\" in firebase database";
    F_CODE["FDI_404_06_0005"] = "Cannot find requested datev property \"defaultDebitorAccount\" in firebase database";
    F_CODE["FDI_404_06_0006"] = "Cannot find requested datev property \"defaultCreditorAccount\" in firebase database";
    F_CODE["FDI_404_06_0007"] = "Cannot find requested datev property \"clientNumber\" in firebase database";
    F_CODE["FDI_404_06_0008"] = "Cannot find requested datev property \"consultantNumber\" in firebase database";
    F_CODE["FDI_404_06_0009"] = "Cannot find requested datev property \"skr\" in firebase database";
    F_CODE["FDI_404_06_0010"] = "Cannot find requested datev property \"fiscalYears\" in firebase database (might be empty)";
    F_CODE["FDI_404_06_0011"] = "Cannot find requested operating unit in firebase database";
    F_CODE["FDI_404_06_0012"] = "Cannot find requested resource in firebase database";
    F_CODE["FDI_404_06_0013"] = "Cannot find requested app company in firebase database";
    /* 500 */
    F_CODE["FDI_500_06"] = "General firebase problem (internal server error)";
    F_CODE["FDI_500_06_0001"] = "Cannot retreive file from storage";
    F_CODE["FDI_500_06_0002"] = "Cannot extract collection path from resource type";
    F_CODE["FDI_500_06_0003"] = "Cannot handle unknown receipt type";
    F_CODE["FDI_500_06_0004"] = "Cannot handle unsupported resource type";
    // =================================
    // AREA: 10 - CSV endpoint / service
    // =================================
    /* 400 */
    F_CODE["FDI_400_10"] = "General CSV api problem (bad request)";
    F_CODE["FDI_400_10_0001"] = "CSV exports for multiple years are not permitted";
    F_CODE["FDI_400_10_0002"] = "dateTo has to be greater than dateFrom";
    F_CODE["FDI_400_10_0003"] = "Receipt needs a receipt number";
    F_CODE["FDI_400_10_0004"] = "Cannot process empty CSV";
    F_CODE["FDI_400_10_0005"] = "Cannot process receipt without a receipt number";
    F_CODE["FDI_400_10_0006"] = "Cannot process empty receipt";
    F_CODE["FDI_400_10_0007"] = "Cannot extract fiscal year start for the requested export";
    F_CODE["FDI_400_10_0008"] = "Cannot process receipt which results in an empty CSV export";
    F_CODE["FDI_400_10_0009"] = "Cannot process receipt without a date";
    F_CODE["FDI_400_10_0010"] = "Cannot process incoming receipt without a costAccountId";
    /* 403 */
    F_CODE["FDI_403_10"] = "General CSV api problem (forbidden)";
    F_CODE["FDI_403_10_0001"] = "clientNumber mismatch";
    F_CODE["FDI_403_10_0002"] = "consultantNumber mismatch";
    /* 404 */
    F_CODE["FDI_404_10"] = "General CSV api problem (not found)";
    /* 500 */
    F_CODE["FDI_500_10"] = "General CSV api problem (internal server error)";
    F_CODE["FDI_500_10_0001"] = "CSV validation failed";
    F_CODE["FDI_500_10_0002"] = "CSV creation failed";
    F_CODE["FDI_500_10_0003"] = "Cannot update receipt link field in csv";
    F_CODE["FDI_500_10_0004"] = "Cannot update all receipt links in csv";
    F_CODE["FDI_500_10_0005"] = "Illegal account numbers detected";
    F_CODE["FDI_500_10_0006"] = "Cannot generate buchungsstapel";
    F_CODE["FDI_500_10_1000"] = "CSV comparison failed";
    // ======================================
    // AREA: 11 - Transfer endpoint / service
    // ======================================
    /* 400 */
    F_CODE["FDI_400_11_0001"] = "Receipt already transferred";
    F_CODE["FDI_400_11_0002"] = "Cannot create empty batch transfer";
    F_CODE["FDI_400_11_0003"] = "Cannot process empty batch transfer";
    F_CODE["FDI_400_11_0004"] = "Cannot run two batch transfers at the same time";
    /* 404 */
    F_CODE["FDI_404_11"] = "General Transfer api problem (not found)";
    /* 500 */
    F_CODE["FDI_500_11"] = "General Transfer api problem (internal server error)";
    F_CODE["FDI_500_11_0001"] = "Cannot start batch transfer - not all receipts are found in firebase";
    // ======================================
    // AREA: 12 - PDF endpoint / service
    // ======================================
    /* 400 */
    F_CODE["FDI_400_12"] = "General PDF api problem (bad request)";
    F_CODE["FDI_400_12_0001"] = "PDF too large - Maximum file size is 10MB";
    /* 404 */
    F_CODE["FDI_404_12"] = "General PDF api problem (not found)";
    /* 500 */
    F_CODE["FDI_500_12"] = "General PDF api problem (internal server error)";
    F_CODE["FDI_500_12_0001"] = "Cannot create PDF";
    // ===================
    // AREA: 90 - DATABASE
    // ===================
    /* 500 */
    F_CODE["FDI_500_90"] = "General database problem (internal server error)";
    F_CODE["FDI_500_90_0001"] = "Query failed";
})(F_CODE || (exports.F_CODE = F_CODE = {}));
