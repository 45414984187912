import { lazy } from "react";
import { withOnlyImplicitOwner } from "@/components/authentication/Session/withOnlyImplicitOwner";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const ResourcesTagsPageContent = lazy(() => import("./ResourceTagsPageContent"));

function ResourcesTagsPageInternal() {
    return (
        <DefaultPage>
            <ResourcesTagsPageContent />
        </DefaultPage>
    );
}

export const ResourcesTagsPage = withOnlyImplicitOwner()(ResourcesTagsPageInternal);
