import { lazy } from "react";
import { withOnlyImplicitOwner } from "@/components/authentication/Session/withOnlyImplicitOwner";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const CostAccountsPageContent = lazy(() => import("./CostAccountsPageContent"));

function CostAccountsPageInternal() {
    return (
        <DefaultPage>
            <CostAccountsPageContent />
        </DefaultPage>
    );
}

export const CostAccountsPage = withOnlyImplicitOwner()(CostAccountsPageInternal);
